import { useState } from 'react';
import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { List, Accordion, Title, Header, Body } from '@strategies/ui';

import Section from '../Section';


export default observer(function Process() {
    const { bindTo } = useHenshu();
    const [phase, setPhase] = useState(0);

    return (
        <Section index={2} name="Process">
            <div className="timeline">
                <henshu.each {...bindTo('Process.items')}>
                    {(bindToEach, i) => (
                        <div
                            key={i}
                            onClick={() => setPhase(i)}
                            className={`block ${i === phase ? 'active' : ''}`}
                        >
                            {i !== bindTo('Process.items').get().length-1 ? <>
                                <henshu.h4 {...bindToEach('name')} />
                                <henshu.h5 {...bindToEach('title')} />
                            </> : (
                                <henshu.h4 {...bindToEach('title')} />
                            )}

                        </div>
                    )}
                </henshu.each>
            </div>

            <List mode="radio" value={phase} onChange={setPhase}>
                <henshu.each {...bindTo('Process.items')}>
                    {(bindToEach, i) => (
                        <Accordion key={i} color={'#A41E35'} value={i}>
                            <Title>
                                {i !== 3 && <>
                                    <henshu.span {...bindToEach('name')} /> -&nbsp;
                                </>}

                                <henshu.span {...bindToEach('title')} />
                            </Title>
                            <Header><henshu.span {...bindToEach('duration')} /></Header>
                            <Body>
                                <henshu.richtext {...bindToEach('body')} />

                                {/* {i === 0 && (
                                    <henshu.a className="share-link" href="#share" {...bindTo('share-link')} />
                                )} */}
                            </Body>
                        </Accordion>
                    )}
                </henshu.each>
            </List>
        </Section>
    );
});
