const firebaseConfig = {
  apiKey: "AIzaSyAP0GNCkA94xCrgwBtN0xxDHVJSisTXau8",
  authDomain: "temple-university-b2c3c.firebaseapp.com",
  projectId: "temple-university-b2c3c",
  storageBucket: "temple-university-b2c3c.appspot.com",
  messagingSenderId: "837518189133",
  appId: "1:837518189133:web:6041d14d9479250e3cf204"
};

export default firebaseConfig;
