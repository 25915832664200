/*
 * Configuration
 */

export const ENGAGEMENT_LINK = 'https://comap.sasaki.com/temple';

export const HEAP_ID = '';

export const FORMSPREE_URL = 'https://formspree.io/f/xnqyllww';

export const MOBILE_BREAKPOINT = 1200;

export const MENU = [
    { text: 'Welcome', href: '#welcome' },
    { text: 'Vision', href: '#vision' },
    { text: 'Process', href: '#process' },
    { text: 'Resources', href: '#resources' },
    { text: 'Share Your Ideas', href: '#share' },
];
