import { useState } from 'react';
import { observer } from 'mobx-react';
import { FiChevronDown } from 'react-icons/fi';
import { Anchor } from '@strategies/react-anchors';
import { henshu, useHenshu } from '@strategies/henshu';

import Section from '../Section';


export default observer(function Welcome() {
    const { bindTo } = useHenshu();
    const [promptHidden, setPromptHidden] = useState(false);

    return (
        <div className="Welcome-wrap">
            <Section name="Welcome" index={0}>
                <Anchor onChange={(anchor: any) => setPromptHidden(anchor.placement === 'offscreen')} />

                <div className="links">
                    <henshu.richtext {...bindTo('calendar.body')} />

                    <henshu.a
                        className="button with-arrow"
                        href={'#vision'}
                        {...bindTo('calendar.button')}
                    />
                </div>
            </Section>

            <footer
                className={promptHidden ? 'hidden' : ''}
                onClick={() => window.scrollTo({ top: window.innerHeight - 100, left: 0, behavior: 'smooth' })}
            >
                <FiChevronDown />
            </footer>
        </div>
    );
});
