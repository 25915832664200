import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { WatchAnchors } from '@strategies/react-anchors';
import ReactMobilized from '@strategies/react-mobilized';

import './App.scss';

import { ContextData, Context } from './context';
import { ENGAGEMENT_LINK, MENU, MOBILE_BREAKPOINT } from './config';
import Contact from './components/Contact/Contact';
import Process from './components/Process/Process';
import Resources from './components/Resources/Resources';
import Vision from './components/Vision/Vision';
import Welcome from './components/Welcome/Welcome';
import ArrowIcon from './assets/arrow-white.svg';
import Logo from './assets/logo.png';
import FooterLogo from './assets/logo-footer.png';


export default observer(function App() {
    const { bindTo } = useHenshu();
    const [data, setData] = useState<ContextData>({ section: 0 });
    const update = useCallback((changes: ContextData) => setData({ ...data, ...changes }), [data, setData]);

    const { section } = data;

    const context = { ...data, update };

    return (
        <div className="App">
            <ReactMobilized
                anchors={MENU}
                logo={<><img src={Logo} alt="Logo" /><henshu.h1 {...bindTo('title')} /></>}
                banner={<henshu.a href={ENGAGEMENT_LINK} {...bindTo('banner')} />}
                breakpoint={MOBILE_BREAKPOINT}
                section={section! + 1}
                setCurrentSection={(n: number) => update({ section: n - 1 })}
            >
                <Context.Provider value={context}>
                    <WatchAnchors>
                        <Welcome />
                        <Vision />
                        <Process />
                        <Resources />
                        <Contact />
                    </WatchAnchors>
                </Context.Provider>

                <footer>
                    <div className="logo">
                        <img src={FooterLogo} alt="Logo" />
                        <henshu.h3 {...bindTo('landing.subheader')} />
                    </div>

                    <button onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                        <img src={ArrowIcon} alt="Back to top" />
                    </button>
                </footer>
            </ReactMobilized>
        </div>
    );
});
